<template>

<app-content class="delete" :loading="is.loading">

	<app-icon icon="warning" class="delete-icon" />

	<div class="delete-title">Delete {{ noun }}</div>

	<div class="delete-text">Are you sure you wish to delete the {{ noun }} <b>{{ name }}</b>? This cannot be reversed.</div>

	<div class="delete-buttons">

		<div class="delete-buttons-item is-danger" v-on:click="onConfirmClick">
			<template v-if="!is.deleting">Confirm</template>
			<app-icon icon="loading" v-if="is.deleting" />
		</div>

		<div class="delete-buttons-item" v-on:click="onCancelClick">Cancel</div>

	</div>

</app-content>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: true,
				deleting: false
			},
			name: ''
		}

	},

	computed: {

		noun: function() {

			return this.$route.meta.noun

		}

	},

	created: function() {

		this.$api.get(this.$endpoint(this.$route.meta.endpoint)).then(function(json) {

			this.title = json.name

			this.is.loading = false

		}.bind(this))

	},

	methods: {

		onConfirmClick: async function() {
			
			this.is.deleting = true

			await this.$api.delete(this.$endpoint(this.$route.meta.endpoint))

			this.$notify({
				message: ['You have successfully deleted the', this.noun, '<b>', this.name, '</b>.']
			})

			this.$router.push({
				name: this.$route.meta.complete
			})
			
		},

		onCancelClick: function() {

			this.$router.push({
				name: this.$route.meta.back,
				params: {
					id: this.$route.params.id
				}
			})

		}

	}

}

</script>

<style scoped>

.delete {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #f25151;
}

.delete >>> .delete-icon {
	font-size: 96px;
}

.delete-title {
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0px;
}

.delete-text {
	font-size: 16px;
	line-height: 20px;
	padding: 0px 100px;
	text-align: center;
	color: #333;
	font-weight: 300;
}

.delete-buttons {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

.delete-buttons-item {
	width: 150px;
	height: 40px;
	border: 2px solid #ccc;
	line-height: 36px;
	text-align: center;
	border-radius: 16px;
	color: #333;
	margin: 0px 10px;
	font-size: 16px;
	cursor: pointer;
}

.delete-buttons-item.is-danger {
	color: #fff;
	font-weight: 400;
	border-color: #f25151;
	background-color: #f25151;
}

</style>
